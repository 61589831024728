import React, { useEffect, useState } from 'react';
import './Rewards.css';  // Import custom styles
import TopLeftMenu from './TopLeftMenu';
import Footer from './Footer';
global.process = require('process');
import szn0 from './images/szn0.png';
import rewardbanner from './images/rewardbanner.png';
import rewardbannersmall from './images/rewardbannersmall.png';  // Add the small banner image import
import rewardtiers from './images/rewardsLine.png'; // Default image
import rewardtiersVert from './images/rewardsLinevert.png'; // Vertical image
import prizecollage from './images/prizeCollage.png';
import prizecollagevert from './images/prizeCollagevert.png';
import ctaWoo from './images/ctaButton_rewards.svg';
import iphonereward from './images/rewardphone1.png';
import iphonereward2 from './images/rewardphone2.png';
import prize2 from './images/prize2.png';
import prize1 from './images/prize1.png';
import FAQAccordion from './FAQAccordion';

function Rewards() {
  const [rewardImage, setRewardImage] = useState(rewardtiers);
  const [bannerImage, setBannerImage] = useState(rewardbanner);  // State for the banner image
  const [collageImage, setCollageImage] = useState(prizecollage);

  const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

  useEffect(() => {
    // Adjust the reward image, banner image, and background image based on screen size
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setRewardImage(rewardtiersVert); // Set to vertical image on smaller screens
        setBannerImage(rewardbannersmall); // Set to small banner image on smaller screens
        setCollageImage(prizecollagevert); // Set to small banner image on smaller screens

      } else {
        setRewardImage(rewardtiers); // Set to default image on larger screens
        setBannerImage(rewardbanner); // Set to default banner image on larger screens
        setCollageImage(prizecollage);

      }
    };

window.addEventListener('resize', handleResize);
    handleResize();  // Initial check for screen size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div className="rewards-container">
      <TopLeftMenu /> {/* Add the menu here */}
      <div className="rewards-content">
       <div className="image-container">
          <img src={szn0} alt="Szn" className="game-image sznimage" />
          <div style={{ height: '12vh' }}></div>
          <img src={bannerImage} alt="Play Live" className="playimage" /> {/* Use the bannerImage state */}
        </div>
        <div style={{ height: '10vh' }}></div>
        <div className="centered-text">
          <h3>
            <span style={{ color: 'white' }}>WIN </span>
            <span style={{ color: '#00FF8D' }}>REWARDS</span>
          </h3>
          <p>Join Arcade SZN 0 now to play along with The NBA 24-25’ Regular Season.</p>
          <p>Your weekly score will determine which lootbox you earn each week.</p>
          <br />
          <p> Every week is a new opportunity to win amazing prizes, so don't wait to get in the game! </p>
        </div>
        <div style={{ height: '10vh' }}></div>
        <div className="image-container">
          <img src={rewardImage} alt="Szn" className="game-image reward-image" />
        </div>
        <div style={{ height: '10vh' }}></div>
        <div className="centered-text">
          <h4 style={{ color: 'white' }}> PLAY NOW TO WIN!</h4>
          <br />
          <a href="https://t.me/fan_arcade_bot" target="_blank" rel="noopener noreferrer">
            <button className="hero-button">
              <img src={ctaWoo} alt="CTA Button" />
            </button>
          </a>
        </div>
        <div style={{ height: '15vh' }}></div>
        <div className="prizecontainer">
  <img src={collageImage} alt="Play Live" className="playimage" />
</div>
        
        <div className="container">
          <div className="iphone-image-container-left2">
            <div className="image-container2">
              <img src={iphonereward} alt="iPhone 1" className="iphone-image iphone1" />
              <img src={iphonereward2} alt="iPhone 2" className="iphone-image iphone2" />
            </div>
          </div>
          <div className="text-container-left2">
            <h3>
              <span style={{ color: 'white' }}>CLAIM </span>
              <span style={{ color: '#8D67FF' }}>YOUR LOOT</span>
            </h3>
            <br />
            <p>Crack open your lootbox collection at the end of SZN 0 and start the 2025 playoffs off right!</p>
            <br />
            <p>Don't drop the ball, get in the game now to start earning loot!</p>
            <br />
            <br />
            <a href="https://t.me/fan_arcade_bot" target="_blank" rel="noopener noreferrer">
              <button className="hero-button">
                <img src={ctaWoo} alt="CTA Button" />
              </button>
            </a>
          </div>
        </div>
        <div style={{ height: '10vh' }}></div>
        <FAQAccordion />
        <div style={{ height: '10vh' }}></div>
        <Footer />
         <button
        className="scroll-to-top"
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="chevron-icon"
        >
          <polyline points="18 15 12 9 6 15" />
        </svg>
      </button>
      </div>
    </div>
  );
}

export default Rewards;
