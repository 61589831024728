import React from 'react';
import './App.css'; // Assuming custom styles are handled in Footer.css
import telegramIcon from './images/telegram.svg';
import xIcon from './images/x.svg';
import linkedinIcon from './images/linkedin.svg';
import fanlogo2 from './images/fanlogo2.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo and Social Media Section */}
        <div className="footer-section logo-social">
          <div className="logo">
            <img src={fanlogo2} alt="FAN.IO Logo" />
            <span>FAN.IO</span>
          </div>
          <div className="social-icons">
            <a href="https://t.me/fan_arcade_bot" target="_blank" rel="noopener noreferrer">
              <img src={telegramIcon} alt="Telegram" />
            </a>
            <a href="https://x.com/fan_io" target="_blank" rel="noopener noreferrer">
              <img src={xIcon} alt="X" />
            </a>
            <a href="https://www.linkedin.com/company/fan-io/" target="_blank" rel="noopener noreferrer">
              <img src={linkedinIcon} alt="LinkedIn" />
            </a>
          </div>
        </div>

        {/* PLAY Section */}
        <div className="footer-section">
          <h4>PLAY</h4>
          <ul>
            <li><a href="https://t.me/fan_arcade_bot" target="_blank" rel="noopener noreferrer">ARCADE</a></li>
            <li><a href="https://t.me/fan_arcade" target="_blank" rel="noopener noreferrer">ANNOUNCEMENTS</a></li>
          </ul>
        </div>

        {/* LEGAL Section */}
        <div className="footer-section">
          <h4>LEGAL</h4>
          <ul>
            <li>
              <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=4e7d8f38-0fdc-41fe-85a7-43aba1b783e4" target="_blank" rel="noopener noreferrer">
                TERMS & CONDITIONS
              </a>
            </li>
            <li>
              <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=6d7b0450-90e8-4aa5-9f1f-e07c930a5bc4" target="_blank" rel="noopener noreferrer">
                PRIVACY POLICY
              </a>
            </li>
          </ul>
        </div>

        {/* PARTNERS Section */}
        <div className="footer-section">
          <h4>PARTNERS</h4>
          <ul>
            <li>WINCAST</li>
            <li>BLUM</li>
          </ul>
        </div>
        <div style={{ height: '11vh' }}></div>
      </div>
    </footer>
  );
}

export default Footer;
