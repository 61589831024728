import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import './TopLeftMenu.css';

function TopLeftMenu() {
  const [showMenu, setShowMenu] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;
      setIsVisible(isScrollingUp);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <>
      {isVisible && (
        <button
          className="hamburger-menu"
          onClick={() => setShowMenu(!showMenu)}
        >
          {showMenu ? '✕' : '☰'}
        </button>
      )}

      {showMenu && (
        <div className="menu-overlay" onClick={() => setShowMenu(false)}>
          <div className="menu-content">
            <Link
              to="/#"
              className="menu-item"
              onClick={() => setShowMenu(false)}
            >
              HOME
            </Link>
            <Link
              to="/rewards#"
              className="menu-item"
              onClick={() => setShowMenu(false)}
            >
              REWARDS
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default TopLeftMenu;
