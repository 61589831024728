import React, { useState } from 'react';
import './Rewards.css';  // Import custom styles

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    { 
      question: 'How do I win lootboxes for prizes?', 
      answer: [
        'Set live predictions on Playmaker for NBA on the FAN.io Arcade on Telegram for games throughout the NBA 2024-2025 season and you will win lootboxes that you can use to get major prizes and airdrops.',
        '',
        'Points will be tallied up at the end of the week and will give you lootboxes based on your score. You’re competing with fans around the world, so the more you play, the more you can win!'
      ]
    },
    { question: 'What can I win?', answer: [
    'Throughout our first season, we’re giving away a million dollars* in prizes across $FAN Airdrops, real-world prizes, collectibles, and in-game power-ups. Potential prizes include tickets to the NBA Finals, NBA League Pass subscriptions, PS5 consoles, NBA2K downloads, sports collectibles like cards and jerseys, and more.  ',
  '',
  'Each Lootbox you win will contribute to your global standing and automatically qualify you to receive major prizes based on your position. Play now and WIN BIG!'
] },
    { question: 'How many games can I enter each day?', answer:
    ['Each day, you’ll be able to enter 3 games for free using “Energy” that will be given to users. You will be able to earn points for lootboxes during every game you enter, so pick your games and strategies wisely.'] },
    { question: 'How many points do I need for each Lootbox?', answer: [
        'The point requirement for each giveaway tier is as follows:',
        '',
        '- Basic giveaway entries - 1000 points',
        '',
        '- Rare giveaway entries - 2500 points',
        '',
        '- Epic giveaway entries - 5000 points',
        '',
        '- Legendary giveaway entries - 10000 points',
        '',
        '- Ultimate giveaway entries - 15000 points',
        '',
        '* Weekly point requirements are subject to change'
      ] },
    { question: 'What is $FAN token?', answer: ['$FAN is an upcoming token that drives the interactive gameplay during live broadcasts for some of the world’s largest sports leagues.',
  'Expected Q2 2025, Arcade SZN 0 is just the start...'] },
    { question: 'When will Arcade SZN 0 end?', answer: ['Arcade SZN 0 will last until the NBA 2024-2025 playoffs which begins on April 20, 2025'] },
    { question: 'Where can I follow FAN.io Arcade official channels for updates on games, prizes, and more?', answer: [
      'X:',<a href="https://x.com/fan_io" target="_blank" rel="noopener noreferrer">https://x.com/fan_io</a>,
      <br />,
      'Telegram:',
      <a href="https://t.me/fan_arcade" target="_blank" rel="noopener noreferrer">https://t.me/fan_arcade</a>
    ]  },
  ];

  return (
    <div className="faq-container">
      <h4 style={{ color: 'white' }}>FREQUENTLY ASKED</h4>
      <h3 style={{ color: '#F55302' }}>QUESTIONS</h3>
      <div className="accordion">
        {faqData.map((item, index) => (
          <div className="accordion-item" key={index}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
              <span>{activeIndex === index ? '−' : '+'}</span>
              <p>{item.question}</p>
            </div>
            {activeIndex === index && (
              <div className="accordion-body">
                {item.answer.map((paragraph, i) => (
                  // If paragraph is an empty string, add extra space
                  <React.Fragment key={i}>
                    {paragraph === '' ? <br /> : <p>{paragraph}</p>}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQAccordion;
