import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-PSZBXFWD'
}

TagManager.initialize(tagManagerArgs)

const rootDoc = document.getElementById('root')
if (!rootDoc) {
  // houston we got a problem
  console.error('could not find root document')
} else {
  const root = ReactDOM.createRoot(rootDoc);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
